<template>
  <div id="p1s3" v-if="!DV_isCheckingAuthentication" class="m-5 grid grid-cols-10 gap-4">
    <div class="col-span-3">

      <!-- Header - logo + menu button -->
      <c9MainMenu P_activePage="transactions"></c9MainMenu>

      <h1 class="font-bold text-2xl text-left mt-5">Transactions</h1>
      <p class="text-left mb-1">{{C_employeeEmail || ""}}</p>
    
      <div id="notifications" @click.stop="DV_isNotificationsOpened = !DV_isNotificationsOpened" @blur="closeNotifocations" class="absolute right-12 top-14 cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell">
              <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
              <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
            </svg>

            <span v-if="DV_unreadNotificationsCount > 0" class="badge">{{DV_unreadNotificationsCount}}</span>

            <transition
              enter-active-class="transform transition duration-500 ease-custom"
              enter-class="-translate-y-1/2 scale-y-0 opacity-0"
              enter-to-class="translate-y-0 scale-y-100 opacity-100"
              leave-active-class="transform transition duration-300 ease-custom"
              leave-class="translate-y-0 scale-y-100 opacity-100"
              leave-to-class="-translate-y-1/2 scale-y-0 opacity-0"
            >
              <div class="absolute right-0 top-8 mb-4 bg-white divide-y rounded-b-lg drop-shadow-lg overflow-hidden w-[30rem] z-10" v-show="DV_isNotificationsOpened">
                
                <!-- First row (Actions) -->
                <div class="flex flex-row items-center justify-between p-2">
                  <span v-if="DV_selectNotifications === false">You have {{DV_unreadNotificationsCount}} new Notifications</span>
                  <div v-if="DV_selectNotifications === true">
                    <span @click.stop="selectAllNotifications" class="chip mr-2">Select All</span>
                    <span @click.stop="unSelectAllNotifications" class="chip mr-2">Unselect All</span>
                    <span @click.stop="readAllSelectedNotifications" class="chip mr-2">Mark as Read</span>
                    <span @click.stop="deleteAllSelectedNotifications" class="chip red mr-2">Delete Selected</span>
                  </div>
                  <span v-if="DV_selectNotifications === false" @click.stop="DV_selectNotifications = true" class="chip ml-auto mr-2">Select</span>
                  <span v-if="DV_selectNotifications === true" @click.stop="DV_selectNotifications = false; DV_selectedNotificationIds = []" class="chip ml-auto mr-2">Cancel</span>
                  <svg @click.stop="closeNotifocations" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </div>

                <!-- List of notifications -->
                <ul class="notifications-list">
                  <!-- Sales Dashboard -->
                  <li 
                    v-for="notification in DV_notificationsList" 
                    :class="[
                        'flex', 'items-start', 'justify-between', 'font-normal', 'border-t', 'menu-list-item', 'text-left', 'font-medium', 'cursor-pointer', 
                        'transition-colors', 'duration-300', 'hover:bg-gray-300', 'z-10', 'w-full', '!mt-0',
                        {'bg-gray-200': notification.isNew}
                    ]"
                    @click.stop="openOrSelectNotification(notification)"
                  >

                    <div v-if="DV_selectNotifications === true">
                      <input type="checkbox" :checked="DV_selectedNotificationIds.includes(notification.uid)">
                    </div>
                    <div :class="[DV_selectNotifications === true ? ' w-[26rem]' : ' w-[28rem]']">
                      <router-link to="sales-dashboard">
                      <!-- Notification title -->
                      <div class="font-bold truncate flex justify-between items-center">
                        <span>{{notification.title}}</span>
                        <!-- Unread / Delete Notification icons -->
                        <div v-if="DV_selectNotifications === false" class="flex items-center">
                          <!-- Unread -->
                          <svg v-if="notification.isNew === true" @click.stop="markTheNotificationAsRead(notification)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6 text-red-900">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                          </svg>
                          <!-- Read -->
                          <svg v-else @click.stop="confirmAndMarkTheNotificationAsUnRead(notification)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z" />
                          </svg>
                          <!-- Delete -->
                          <svg @click.stop="confirmAndDeleteTheNotification(notification)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6 text-red-900">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                          </svg>
                        </div>
                      </div>
                      
                      <div class="truncate">{{notification.description}}</div>
                      <div class="truncate" v-if="notification.type.includes('payment') && notification.payment_description">
                        {{notification.payment_description}}
                      </div>
                      <div class="truncate" v-if="notification.type.includes('message') && notification.message_description">
                        {{notification.message_description}} <br> <span class="text-gray-500">{{notification.summary}}</span>
                      </div>
                      
                      <!-- Notification date -->
                      <div class="text-sm font-bold">{{notification.formatted_time}}</div>
                      </router-link>
                    </div>
                  </li>
                </ul>
              </div>
            </transition>
          </div>
      
        <!-- Transactions data display -->
       <div class="m-3 border border-gray-400 rounded-xl p-2">
          <p1s4c4TransactionsData
            :P_calculatedSuccessfulTransactions="DV_totalSales"
            :P_calculatedSuccessfulTransactionAmount="DV_totalSalesAmount"
            :P_calculatedPendingPaymentLinks="DV_totalUnpaid"
            :P_calculatedPendingPaymentAmount="DV_totalPendingAmount"
            :P_calculatedProcessedCredits="DV_totalCredits"
          />
      </div>
    </div>
    
    <!-- Filters, search, date ranges, etc. -->
    <div class="col-span-7 mt-4 m-3">
      <div class="border border-gray-400 rounded-xl h-full">
        <p1s4c1Filters
          :paymentLinks="DV_paymentLinks"
          @event-transaction-type-filter-selected="setTransactionTypeFilter"
          @event-payment-status-filter-selected="setPaymentStatusFilter"
          @event-card-type-filter-selected="setCardTypeFilter"
          @event-search-filter-selected="setSearchFilter"
          @event-start-date-filter-selected="setStartDateFilter"
          @event-end-date-filter-selected="setEndDateFilter"
          @event-quick-date-filter-selected="setQuickDateFilter"
          @event-entries-per-page-filter-selected="setEntriesPerPageFilter"
          @event-column-filter-selected="setColumnFilter"
        />
      </div>
    </div> 
 
    <!-- Payment Links Data Frame -->
    <div class="col-span-full">
            
      <div class="rounded-xl selectable-text">
        <p1s4c2PaymentLinks
          :paymentLinks="visiblePaymentLinks"
          :entriesPerPageFilter="DV_entriesPerPageFilter"
          :startDateFilter="DV_startDateFilter"
          :endDateFilter="DV_endDateFilter"
          :searchFilter="DV_searchFilter"
          :transactionTypeFilter="DV_transactionTypeFilter"
          :paymentStatusFilter="DV_paymentStatusFilter"
          :cardTypeFilter="DV_cardTypeFilter"
          :quickDateFilter="DV_quickDateFilter"
          :columnFilter="DV_columnFilter"  
        />

        <!-- Pagination -->
        <div v-if="totalPages !== 0" class="flex justify-between items-center mt-2 m-3">
          <div>
            <button v-if="DV_currentPage > 1" @click="goToPage(DV_currentPage - 1)" class="btn btn-lime text-center mr-2 w-10">&#8249;</button>
            <button v-if="DV_currentPage <= 1" class="btn btn-white text-black text-center mr-2 w-10 cursor-not-allowed text-lg">&#8249;</button>
          </div>
            
          <div class="flex">
            <div class="border border-gray-400 rounded-xl p-2">
              <span 
                v-for="pageIndex in totalPages" 
                :key="pageIndex" 
                class="text-xl p-1" 
                @click="goToPage(pageIndex)" 
                :class="{ 'text-blue-500 cursor-pointer': pageIndex !== DV_currentPage }"
              >
                {{ pageIndex }}
              </span>
            </div>
          </div>
          <div>
            <button v-if="hasNextPage" @click="goToPage(DV_currentPage + 1)" class="btn btn-lime text-center mr-2 w-10 text-lg">&#8250;</button>
            <button v-if="!hasNextPage" class="btn btn-white text-black text-center mr-2 w-10 cursor-not-allowed text-lg">&#8250;</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import moment, { utc } from "moment";
import p1s4c1Filters from "./p1s4c1Filters.vue";
import p1s4c2PaymentLinks from "./p1s4c2PaymentLinks.vue";
import p1s4c4TransactionsData from "./p1s4c4TransactionsData.vue";
import c9MainMenu from "../shared/c9MainMenu.vue";
import generalFunctions from "../../mixins/generalFunctions";
import m2MiscDataFetchers from "../../mixins/m2MiscDataFetchers";
import "moment-timezone";

export default {
  name: "p1s4Transactions",

  components: { 
    p1s4c1Filters,
    p1s4c2PaymentLinks,
    p1s4c4TransactionsData,
    c9MainMenu,
  },

  mixins: [m2MiscDataFetchers, generalFunctions],

  data() {
    return {
      DV_employeesInfo: {},
      DV_activeUserInfo: window.G_bi2_data.IV_active_employee_info,
      DV_consumerContactsArrayLookup: window.G_bi2_data.IV_al_consumer_contacts,
      DV_consumerConversationsHistory: window.G_bi2_data.IV_consumer_contact_conversation_history_index,
      DV_cchIndex: window.G_bi2_data.IV_al_consumer_contact_conversation_history_index,
      DV_employeePermissions: {},
      DV_entriesPerPageFilter: 10,
      DV_startDateFilter: moment().format("YYYY-MM-DD"),
      DV_endDateFilter: moment().format("YYYY-MM-DD"),
      DV_searchFilter: "",
      DV_transactionTypeFilter: "All",
      DV_paymentStatusFilter: "All",
      DV_quickDateFilter: "Today",
      DV_cardTypeFilter: "All",
      DV_columnFilter: {
        "order_number": { name: 'Order Number', visible: true },
        "payment_link_created": { name: 'Payment Link Created', visible: true },
        "payment_timestamp": { name: 'Payment Timestamp', visible: true },
        "type": { name: 'Type', visible: true },
        "billing_address": { name: 'Billing Address', visible: false },
        "shipping_address": { name: 'Shipping Address', visible: false },
        "cash_amount": { name: 'Cash Amount', visible: true },
        "card_amount": { name: 'Card Amount', visible: true },
        "status": { name: 'Status', visible: true },
        "brand": { name: 'Brand', visible: true },
        "last4": { name: 'Last 4', visible: true },
        "cardholder_name": { name: 'Cardholder Name', visible: true },
        "description": { name: 'Description', visible: true },
      },
      DV_paymentLinksRaw: {},
      DV_paymentLinks: [],
      DV_consumerConversations: {},
      DV_consumerContactsRaw: {},
      DV_consumerContacts: [],
      moment: moment,
      DV_currentPage: 1,
      DV_totalFilteredPaymentLinks: 0,
      DV_totalSales: 0,
      DV_totalSalesAmount: 0,
      DV_totalUnpaid: 0,
      DV_totalPendingAmount: 0,
      DV_totalCredits: 0,
      DV_totalPartialRefunds: 0,
      DV_totalPendingIdVerify: 0,
      DV_totalDeclined: 0,
      DV_isCheckingAuthentication: true,
      DV_noBusinessFound: false,
      DV_loadingBusinesses: true,
      
      DV_unreadNotificationsCount: 0,
      DV_notificationsList: [],
      DV_isNotificationsOpened: false,
      DV_allNotificationsRef: {},
      DV_allNotificationsReadTimeRef: {},
      DV_selectNotifications: false,
      DV_selectedNotificationIds: [],
      DV_notificationTypeMapping: {
        1: "new-message",
        2: "payment-success",
        3: "payment-failed",
        4: "payment-in-progress",
        5: "payment-refunded",
      },
    };
  },

  mounted() {
    const CI = this;

    CI.authenticationCheck(0);
    CI.startConsumerContactsListeners();
    CI.startPaymentLinksListener(0);

    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }

    CI.startNotificationsListener(0, this);
    CI.DV_recyclerLogos = window.G_bi2_data.IV_recyclers_logo_urls;

    document.addEventListener('click', (e) => {
      if (e.target.classList.contains("notification")) { return; };
      CI.DV_isNotificationsOpened = false;
      CI.DV_selectNotifications = false;
      CI.DV_selectedNotificationIds = [];

    }, false);
    CI.DV_employeesInfo = window.G_bi2_data.IV_recycler_employees;
    CI.DV_consumerConversations = window.G_bi2_data.IV_consumer_conversations;
  },

  computed: { 
    C_employeeEmail() {
      let activeEmployeeUid = window.G_bi2_data.IV_active_employee_info.employee_uid;
    
      if (activeEmployeeUid in window.G_bi2_data.IV_recycler_employees.value === true) {
        let employeeInfo = window.G_bi2_data.IV_recycler_employees.value[activeEmployeeUid];

        return employeeInfo.email_address;
      }

      return "";
    },

    visiblePaymentLinks() {
      const CI = this;      
      let paymentLinks = CI.DV_paymentLinks;

      CI.DV_totalSales = 0;
      CI.DV_totalSalesAmount = 0;
      CI.DV_totalUnpaid = 0;
      CI.DV_totalPendingAmount = 0;
      CI.DV_totalCredits = 0;

      if (CI.DV_transactionTypeFilter != "All") {
        paymentLinks = paymentLinks.filter(paymentLink => paymentLink.transaction_type === CI.DV_transactionTypeFilter);
      }

      if (CI.DV_paymentStatusFilter != "All") {
        paymentLinks = paymentLinks.filter(paymentLink => paymentLink.payment_status === CI.DV_paymentStatusFilter);
      }
  
      if (CI.DV_cardTypeFilter != "All") {
        paymentLinks = paymentLinks.filter(paymentLink => paymentLink.card_type === CI.DV_cardTypeFilter);
      }
      
      // search filter
      if (CI.DV_searchFilter != "") {
        paymentLinks = paymentLinks.filter(paymentLink => {

          if (paymentLink.order_number && paymentLink.order_number.toLowerCase().includes(CI.DV_searchFilter.toLowerCase())) { return true; }
          if (paymentLink.transaction_type && paymentLink.transaction_type.toLowerCase().includes(CI.DV_searchFilter.toLowerCase())) { return true; }
          if (paymentLink.payment_status && paymentLink.payment_status.toLowerCase().includes(CI.DV_searchFilter.toLowerCase())) { return true; }
          if (paymentLink.card_type && paymentLink.card_type.toLowerCase().includes(CI.DV_searchFilter.toLowerCase())) { return true; }
          if (paymentLink.last4_digits && paymentLink.last4_digits.includes(CI.DV_searchFilter.toLowerCase())) { return true;}
          if (paymentLink.cardholder_name && paymentLink.cardholder_name.toLowerCase().includes(CI.DV_searchFilter.toLowerCase())) { return true; }
          if (paymentLink.description && paymentLink.description.toLowerCase().includes(CI.DV_searchFilter.toLowerCase())) { return true; }

          return false;
        })
      }

      // sort latest payment links for pagination
      paymentLinks = paymentLinks.sort((a, b) => {
        return b.date_time.valueOf() - a.date_time.valueOf();
      });

      const startIndex = (CI.DV_currentPage - 1) * CI.DV_entriesPerPageFilter;
      const endIndex = Math.min(startIndex + this.DV_entriesPerPageFilter, this.DV_paymentLinks.length);

      // date range filter
      paymentLinks = paymentLinks.filter(paymentLink => {
        const startDate = CI.DV_startDateFilter;
        const endDate = CI.DV_endDateFilter;
        const creationDate = paymentLink.creation_date;

        return creationDate >= startDate && creationDate <= endDate;
      });

      for (let paymentLink in paymentLinks) {
        let entry = paymentLinks[paymentLink];

        if (entry.payment_status === "Approved") {
          CI.DV_totalSales += 1;
          CI.DV_totalSalesAmount += entry.cash_amount;
        }

        if (entry.payment_status === "Pending Payment" || entry.payment_status === "Pending ID Verification") {
          CI.DV_totalUnpaid += 1;
          CI.DV_totalPendingAmount += entry.cash_amount;
        }

        if (entry.payment_status === "Refunded" || entry.payment_status === "Partial Refund") {
          if (entry.refunded_amount) {
            CI.DV_totalCredits += entry.refunded_amount;
          }
          CI.DV_totalSales += 1;
        }
      }

      CI.DV_totalFilteredPaymentLinks = paymentLinks.length;

      return paymentLinks.slice(startIndex, endIndex);
    },

    hasNextPage() {
      return this.DV_currentPage * this.DV_entriesPerPageFilter < this.DV_totalFilteredPaymentLinks;
    },
    
    totalPages() {
      return Math.ceil(this.DV_totalFilteredPaymentLinks / this.DV_entriesPerPageFilter);
    },
  },

  methods: {
    authenticationCheck(retry_count=0) {
      const CI = this;

      if (retry_count >= 40 && window.location.pathname !== "/login" && window.location.pathname !== "/") {
          location.assign(window.location.origin + `/login`);
        return;
      }

      if (window.G_firebase_auth.IV_is_user_logged_in !== true) {
        setTimeout( function() {CI.authenticationCheck(++retry_count)}, 100);
        return
      };

      CI.DV_isCheckingAuthentication = false;
      CI.dataLoadedCheck(0);
    },

    dataLoadedCheck(retry_count=1) {
      const CI = this;

      if (
        retry_count >= 40 &&
        window.location.pathname !== "/login" && 
        window.location.pathname !== "/" && 
        window.location.pathname !== "/account-setup"
      ) {
        CI.DV_noBusinessFound = true;
        CI.DV_loadingBusinesses = false;
        return;
      }

      if (!window.G_bi2_data.IV_active_employee_info.business_uid || !window.G_bi2_data.IV_active_employee_info.recycler_location_uid) {
        setTimeout( function() {CI.dataLoadedCheck(++retry_count)}, 500);
        return
      };

      CI.DV_noBusinessFound = false;
      CI.DV_loadingBusinesses = false;
    },

    goToNextPage() {
      if (this.hasNextPage) {
        this.DV_currentPage++;
      }
    },

    goToPreviousPage() {
      if (this.DV_currentPage > 1) {
        this.DV_currentPage--;
      }
    },
    
    goToPage(page) {
      this.DV_currentPage = page;
    },

    startPaymentLinksListener(retry_count=0) {
      const CI = this;

      if (retry_count >= 20) { return; }

      if (!CI.DV_activeUserInfo || CI.DV_consumerContacts.length === 0) {
        setTimeout(function() { CI.startPaymentLinksListener(++retry_count) }, 500);
        return;
      }

      for (let consumer in CI.DV_consumerContacts) {
        let entry = CI.DV_consumerContacts[consumer];
        let consumer_uid = entry.uid;

        window.G_bi2_data.startConsumerConversationHistoryIndexListener(
          window.G_bi2_data,
          CI.DV_activeUserInfo.business_uid,
          CI.DV_activeUserInfo.recycler_location_uid,
          consumer_uid,
        )
      }

      CI.DV_consumerConversationsHistory = window.G_bi2_data.IV_consumer_contact_conversation_history_index;
      CI.DV_paymentLinksRaw = window.G_bi2_data.IV_consumer_conversation_payment_links;
    },

    startConsumerContactsListeners(retry_count=0) {
      const CI = this;

      if (!window.G_bi2_data.IV_active_employee_info.business_uid ||
          !window.G_bi2_data.IV_active_employee_info.recycler_location_uid) { 
          
        if (retry_count >= 500) { return; }
        setTimeout(function() { CI.startConsumerContactsListeners(++retry_count)}, 500);
        
        return; 
      }

      window.G_bi2_data.startAllConsumerContactsListener(
        window.G_bi2_data,
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
      );

      this.DV_consumerContactsPending = window.G_bi2_data.IV_consumer_contacts_pending.value;
      this.DV_consumerContactsRaw = window.G_bi2_data.IV_consumer_contacts.value;
      this.populateConsumerContactsList();
    },

    populateConsumerContactsList() {
      const CI = this;
      const index = CI.DV_consumerContactsArrayLookup;
      let consumersList = [];

      // Create customer business joins
      for (let uid in CI.DV_consumerContactsRaw) {
        let consumerContact = {}
        let consumer = CI.DV_consumerContactsRaw[uid]

        consumerContact.full_name = consumer[index.first_name] + " " + consumer[index.last_name]
        consumerContact.email = consumer[index.email]
        consumerContact.phone_number = CI.formatPhoneNumber(consumer[index.primary_phone_number].phone_number)
        consumerContact.rating = consumer[index.average_consumer_rating]
        consumerContact.city = consumer[index.address_info_city_2d[0]][index.address_info_city_2d[1]]
        consumerContact.state = consumer[index.address_info_state_2d[0]][index.address_info_state_2d[1]]
        consumerContact.zip_code = consumer[index.address_info_postal_code_2d[0]][index.address_info_postal_code_2d[1]]
        consumerContact.customer_types = consumer[index.customer_types] || [""]
        consumerContact.uid = uid
        consumerContact.is_deactivated = consumer[index.is_deactivated]
        consumerContact.sales_person = ""

        consumersList.push(consumerContact)
      }

      CI.DV_consumerContacts = consumersList;
      CI.$forceUpdate();
    },

    populatePaymentLinksList() {
      let CI = this;
      let paymentLinksList = [];

      // loop through consumer and their conversations
      for (let consumer_and_conversation_uid in CI.DV_paymentLinksRaw) {
        let paymentLink = CI.DV_paymentLinksRaw[consumer_and_conversation_uid]
        let consumer_contact_uid = consumer_and_conversation_uid.split('-')[0]
        let conversation_uid = consumer_and_conversation_uid.split('-')[1]

        if (Object.keys(paymentLink).length === 0) { continue; }

        // identify admin or sales permission
        if (!CI.DV_employeePermissions.admin) {
          // loop through consumer conversations this employee is active in
          let conversationDetails = CI.DV_consumerConversations[consumer_and_conversation_uid]
          let assignedEmployeeUid = conversationDetails.assigned_employee;

          if (CI.DV_activeUserInfo.employee_uid !== assignedEmployeeUid) {
            continue;
          }
        }
        
        // loop through payment links
        for (let paymentLinkUid in paymentLink) {
          let paymentLinkDetails = {}
          let refundedPaymentLinkDetails = {}
          let doc_data = paymentLink[paymentLinkUid];
          let consumerInfo;
          
          let payment_timestamp = "Unpaid";
          let transaction_type = "Sale";
          
          let timeZoneName = moment.tz(moment.tz.guess()).zoneAbbr()
          let date_time;
          let payment_status = "Pending Payment";
          let order_number;
          let creation_date;
          let card_type = doc_data.recent_transaction_details.card_type;
          let credit_order_number;

          if (consumer_contact_uid in CI.DV_consumerContactsRaw) {
            consumerInfo = CI.DV_consumerContactsRaw[consumer_contact_uid];
          }

          if (doc_data.payment_status === "paid") {
            payment_status = "Approved";

            // add payment paid time to data
            let utcDate = moment.utc(doc_data.payment_paid_time.toDate());
            payment_timestamp = moment(utcDate).local().format("MM/DD/YYYY h:mma") + " " + timeZoneName;
          } else if (doc_data.payment_status === "no_status") {
            if (doc_data.order_number === "" && doc_data.creation_date === "" && consumer_contact_uid in CI.DV_consumerContactsRaw) {
              let details = consumerInfo[window.G_bi2_data.IV_al_consumer_contacts.verified_outputs]
              if (Object.keys(details).length != 0 && details.matched_profile != undefined) {
                if (details.matched_profile === 3) {
                  payment_status = "Pending ID Verification";
                }
              }
            }
          } else if (doc_data.payment_status === "failed") {
            payment_status = "Declined";
          } else if (doc_data.payment_status === "refunded") {
            let utcDate = moment.utc(doc_data.payment_paid_time.toDate());
            payment_timestamp = moment(utcDate).local().format("MM/DD/YYYY h:mma") + " " + timeZoneName;
            
            transaction_type = "Credit";
            payment_status = "Refunded";
          } else if (doc_data.payment_status === "partial_refund") {
            let utcDate = moment.utc(doc_data.payment_paid_time.toDate());
            payment_timestamp = moment(utcDate).local().format("MM/DD/YYYY h:mma") + " " + timeZoneName;
            
            transaction_type = "Credit";
            payment_status = "Partial Refund";
          }

          if (doc_data.order_number === "") {
            order_number = "Unknown";
          } else {
            order_number = doc_data.order_number;
          
            // add -CR to order number if the payment has been refunded partially/fully
            if (payment_status === "Partial Refund" || payment_status === "Refunded") {
              credit_order_number = order_number + "-CR";
            }
          }
          
          if (doc_data.creation_date === "") {
            let conversationHistoryDetails = CI.DV_consumerConversationsHistory[consumer_contact_uid][conversation_uid];

            if (conversationHistoryDetails[CI.DV_cchIndex.chat_start_timestamp] === null) {
              let utcDate = moment.utc(conversationHistoryDetails[CI.DV_cchIndex.chat_finished_timestamp].toDate());
              let localDate = moment(utcDate).local().format("YYYY-MM-DD");
               
              creation_date = localDate;
              date_time = moment(utcDate).local()
            } else {
              let utcDate = moment.utc(conversationHistoryDetails[CI.DV_cchIndex.chat_start_timestamp].toDate());
              let localDate = moment(utcDate).local().format("YYYY-MM-DD"); 
 
              creation_date = localDate;
              date_time = moment(utcDate).local()
            }
          } else {
            let utcDate = moment.utc(doc_data.creation_date.toDate());
            let localDate = moment(utcDate).local().format("YYYY-MM-DD");

            creation_date = localDate;
            date_time = moment(utcDate).local()
          }

          if (card_type != "Unknown") {
            card_type = card_type.charAt(0).toUpperCase() + card_type.slice(1);
          }

          if (!doc_data.description) {
            doc_data.description = "Unknown"
          }

          paymentLinkDetails.payment_timestamp = payment_timestamp;
          paymentLinkDetails.date_time = date_time;
          paymentLinkDetails.consumer_contact_uid = consumer_contact_uid;
          paymentLinkDetails.conversation_uid = conversation_uid;
          paymentLinkDetails.payment_link_uid = paymentLinkUid;

          if (consumerInfo[8]) {
            paymentLinkDetails.billing_address = consumerInfo[8][3];
            paymentLinkDetails.shipping_address = consumerInfo[16][3];

            if (consumerInfo[8][0]) {
              paymentLinkDetails.billing_address += ", " + consumerInfo[8][0];
            }

            if (consumerInfo[8][1]) {
              paymentLinkDetails.billing_address += ", " + consumerInfo[8][1];
            }

            if (consumerInfo[8][2]) {
              paymentLinkDetails.billing_address += ", " + consumerInfo[8][1];
            }

            if (consumerInfo[16][0]) {
              paymentLinkDetails.shipping_address += ", " + consumerInfo[16][0];
            }

            if (consumerInfo[16][1]) {
              paymentLinkDetails.shipping_address += ", " + consumerInfo[16][1];
            }

            if (consumerInfo[16][2]) {
              paymentLinkDetails.shipping_address += ", " + consumerInfo[16][1];
            }
          } else {
            paymentLinkDetails.billing_address = "Unknown";
            paymentLinkDetails.shipping_address = "Unknown";
          }

          paymentLinkDetails.order_number = order_number;
          paymentLinkDetails.cash_amount = doc_data.amount - doc_data.adjusted_amount;
          paymentLinkDetails.card_amount = doc_data.amount;
          paymentLinkDetails.payment_status = payment_status;
          paymentLinkDetails.description = doc_data.description;
          paymentLinkDetails.creation_date = creation_date;
          paymentLinkDetails.last4_digits = doc_data.recent_transaction_details.last4_digits;
          paymentLinkDetails.card_type = card_type;
          paymentLinkDetails.cardholder_name = doc_data.recent_transaction_details.cardholder_name;
          paymentLinkDetails.transaction_type = transaction_type;
          paymentLinkDetails.transaction_id = doc_data.transaction_id;
          paymentLinkDetails.show_tooltip_info = false;
          paymentLinkDetails.downloading_receipt = false;
          paymentLinkDetails.mouse_hover = false;

          if (doc_data.payment_status === "failed") {
            paymentLinkDetails.avs_status_code = doc_data.recent_transaction_details.avs_status_code || doc_data.recent_transaction_details.cvv_status_code;
            paymentLinkDetails.avs_status_description = doc_data.recent_transaction_details.avs_status_description || doc_data.recent_transaction_details.cvv_status_description;
          }

          if (doc_data.payment_status === "partial_refund") {
            refundedPaymentLinkDetails.date_time = date_time;
            refundedPaymentLinkDetails.consumer_contact_uid = consumer_contact_uid;
            refundedPaymentLinkDetails.conversation_uid = conversation_uid;
            refundedPaymentLinkDetails.payment_link_uid = paymentLinkUid;

            refundedPaymentLinkDetails.order_number = credit_order_number;
            refundedPaymentLinkDetails.cash_amount = doc_data.amount - doc_data.adjusted_amount;
            refundedPaymentLinkDetails.card_amount = doc_data.amount;
            refundedPaymentLinkDetails.payment_status = payment_status;
            refundedPaymentLinkDetails.description = doc_data.description;
            refundedPaymentLinkDetails.creation_date = creation_date;

            refundedPaymentLinkDetails.last4_digits = doc_data.recent_transaction_details.last4_digits;
            refundedPaymentLinkDetails.card_type = card_type;
            refundedPaymentLinkDetails.cardholder_name = doc_data.recent_transaction_details.cardholder_name;
            refundedPaymentLinkDetails.transaction_type = transaction_type;
            refundedPaymentLinkDetails.transaction_id = doc_data.transaction_id;
            refundedPaymentLinkDetails.show_tooltip_info = false;
            refundedPaymentLinkDetails.downloading_receipt = false;
            refundedPaymentLinkDetails.mouse_hover = false;

            refundedPaymentLinkDetails.partial_refund_description = `$${doc_data.refunded_amount.toFixed(2)} refund was initiated`;
            refundedPaymentLinkDetails.refunded_amount = doc_data.refunded_amount;
            refundedPaymentLinkDetails.refund_amount = doc_data.refund_amount;
            
            paymentLinksList.push(refundedPaymentLinkDetails)

            paymentLinkDetails.payment_status = "Approved";
            paymentLinkDetails.transaction_type = "Sale";
            paymentLinkDetails.transaction_id = "";
          } else if (doc_data.payment_status === "refunded") {
            refundedPaymentLinkDetails.date_time = date_time;
            refundedPaymentLinkDetails.consumer_contact_uid = consumer_contact_uid;
            refundedPaymentLinkDetails.conversation_uid = conversation_uid;
            refundedPaymentLinkDetails.payment_link_uid = paymentLinkUid;

            refundedPaymentLinkDetails.order_number = credit_order_number;
            refundedPaymentLinkDetails.cash_amount = doc_data.amount - doc_data.adjusted_amount;
            refundedPaymentLinkDetails.card_amount = doc_data.amount;
            refundedPaymentLinkDetails.payment_status = payment_status;
            refundedPaymentLinkDetails.description = doc_data.description;
            refundedPaymentLinkDetails.creation_date = creation_date;

            refundedPaymentLinkDetails.last4_digits = doc_data.recent_transaction_details.last4_digits;
            refundedPaymentLinkDetails.card_type = card_type;
            refundedPaymentLinkDetails.cardholder_name = doc_data.recent_transaction_details.cardholder_name;
            refundedPaymentLinkDetails.transaction_type = transaction_type;
            refundedPaymentLinkDetails.transaction_id = doc_data.transaction_id;
            refundedPaymentLinkDetails.show_tooltip_info = false;
            refundedPaymentLinkDetails.downloading_receipt = false;
            refundedPaymentLinkDetails.mouse_hover = false;
            refundedPaymentLinkDetails.refunded_amount = doc_data.refunded_amount;

            paymentLinksList.push(refundedPaymentLinkDetails);
          
            paymentLinkDetails.payment_status = "Approved";
            paymentLinkDetails.transaction_type = "Sale";
            paymentLinkDetails.transaction_id = "";
          }

          // for successful transactions only
          paymentLinksList.push(paymentLinkDetails);
        }
      }

      CI.DV_paymentLinks = paymentLinksList;
      CI.$forceUpdate();
    },

    setTransactionTypeFilter(filter) {
      this.DV_transactionTypeFilter = filter;
    },

    setSearchFilter(filter) {
      this.DV_searchFilter = filter;
    },
    
    setEntriesPerPageFilter(filter) {
      this.DV_entriesPerPageFilter = filter;
    },
    
    setPaymentStatusFilter(filter) {
      this.DV_paymentStatusFilter = filter;
    },
    
    setCardTypeFilter(filter) {
      this.DV_cardTypeFilter = filter;
    },

    setStartDateFilter(filter) {
      this.DV_startDateFilter = filter;
    },
    
    setEndDateFilter(filter) {
      this.DV_endDateFilter = filter;
    },

    setQuickDateFilter(filter) {
      this.DV_quickDateFilter = filter;
    },

    setColumnFilter(filter) {
      this.DV_columnFilter = filter;
    },

    startNotificationsListener(retry_count=0) {
      const CI = this;

      if (retry_count > 20) { return; }

      if (!window.G_bi2_data.IV_active_employee_info.recycler_location_uid || !window.G_bi2_data.IV_active_employee_info.employee_uid) {
        setTimeout(function() { CI.startNotificationsListener(++retry_count) }, 100);
        return;
      }

      window.G_bi2_data.startConversationNotificationsListener(
        window.G_bi2_data,
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
      );

      window.G_bi2_data.startReadNotificationsListener(
        window.G_bi2_data,
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        window.G_bi2_data.IV_active_employee_info.employee_uid,
      )

      CI.lazyGenerateListOfNotifications();
    },

    lazyGenerateListOfNotifications: _.debounce(function(event) {
      const CI = this

      CI.DV_allNotificationsRef = window.G_bi2_data.IV_user_notifications.value;
      CI.DV_allNotificationsReadTimeRef = window.G_bi2_data.IV_user_notifications_read_time.value;

      let new_notifications_count = 0;

      let notifications = []
      let employeesActiveConversations = window.G_bi2_data.IV_employees_active_conversations.value;

      for (let notification_uid in CI.DV_allNotificationsRef) {
        const notification = CI.DV_allNotificationsRef[notification_uid]
        let notificationListItem = {}

        if (window.G_bi2_data.IV_active_employee_info.permissions.sales_person) {
          if (notification.summary.includes("New Queue Request") || notification.description.includes("New Queue Request")) {} else {
            if (notification.consumer_conversation_uid in employeesActiveConversations === false) { continue; }
          }
        }

        notificationListItem.type = CI.DV_notificationTypeMapping[notification.type];
        notificationListItem.consumer_conversation_uid = notification.consumer_conversation_uid
        notificationListItem.consumer_uid = notification.consumer_uid
        notificationListItem.conversation_uid = notification.conversation_uid
        notificationListItem.title = ""
        notificationListItem.payment_description = ""
        notificationListItem.message_description = ""
        notificationListItem.description = notification.description
        notificationListItem.summary = notification.summary;
        notificationListItem.payment_link_uid = notification.payment_link_uid;
        notificationListItem.formatted_time = moment.unix(notification.timestamp.seconds).fromNow();
        notificationListItem.timestamp = notification.timestamp;
        notificationListItem.isNew = false;
        notificationListItem.uid = notification_uid;
        notificationListItem.force_mark_unread = notification.force_mark_unread;

        // skip notification if conversation not found
        const conv = window.G_bi2_data.IV_consumer_conversations.value[notification.consumer_conversation_uid];
        if (!conv) {continue;}
        
        // Format notification title
        notificationListItem.title += `${this.getPhoneNumber(conv) || ""} ${conv.consumer_name || ""}`;

        // Format payment description
        if (notificationListItem.type.includes("payment") && notification.payment_link_uid) {
          if (notification.consumer_conversation_uid in window.G_bi2_data.IV_consumer_conversation_payment_links.value === true &&
              notification.payment_link_uid in window.G_bi2_data.IV_consumer_conversation_payment_links.value[notification.consumer_conversation_uid] === true
          ) {
            const payment_link = window.G_bi2_data.IV_consumer_conversation_payment_links.value[notification.consumer_conversation_uid][notification.payment_link_uid];
            notificationListItem.payment_description = payment_link.description;
            
            if (notificationListItem.type == "payment-success") {
              notificationListItem.payment_description = `A $${payment_link.amount} payment is paid for ${payment_link.description}`;
            } else if (notificationListItem.type == "payment-failed") {
              notificationListItem.payment_description = `A $${payment_link.amount} payment has failed for ${payment_link.description}`;
            } else if (notificationListItem.type == "payment-in-progress") {
              notificationListItem.payment_description = `A $${payment_link.amount} payment has initiated for ${payment_link.description}`;
            } else if (notificationListItem.type == "payment-refunded") {
              notificationListItem.payment_description = `A $${payment_link.amount} payment has been refunded`;
            }
          }
        }

        // Format message notification description
        if (notificationListItem.type.includes("message")) {
          notificationListItem.message_description = `New message(s) received from ${conv.consumer_name || "customer"}`;
        }

        // Count if new notification
        if (notification.consumer_conversation_uid in CI.DV_allNotificationsReadTimeRef === false) {
          new_notifications_count += 1;
          notificationListItem.isNew = true;
        } else {
          let last_read_time = CI.DV_allNotificationsReadTimeRef[notification.consumer_conversation_uid]
          if (last_read_time && (last_read_time.read_message_time.seconds < notification.timestamp.seconds)) {
            new_notifications_count += 1;
            notificationListItem.isNew = true;
          }
        }

        // Mark and count force unread only if not already count
        if (notification.force_mark_unread === true && notificationListItem.isNew === false) {
          new_notifications_count += 1;
          notificationListItem.isNew = true;
        }

        // Set message count in description if new
        if (notificationListItem.isNew) {
          let localDate = moment().local();
          let secondsThreshold = localDate - (20 * 1000);

          if (new Date(notificationListItem.timestamp.toDate()).getTime() > secondsThreshold) {
            if (document.visibilityState === 'visible') {} // Skip if the tab is visible 
            else {
              // Check if the browser supports notifications
              if (!('Notification' in window)) {
                console.log('This browser does not support desktop notifications');
                return;
              }
            
              let notificationBody = notificationListItem.summary;

              // Check if permission is granted
              if (Notification.permission === 'granted') {
                // If it's okay let's create a notification
                new Notification(notificationListItem.title, {
                  body: notificationBody,
                  icon: CI.DV_recyclerLogo,
                });
              } else if (Notification.permission !== 'denied') {
                // Otherwise, we need to ask the user for permission
                Notification.requestPermission().then(function (permission) {
                  // If the user accepts, let's create a notification
                  if (permission === 'granted') {
                    new Notification(notificationListItem.title, {
                      body: notificationBody,
                      icon: CI.DV_recyclerLogo,
                    });
                  }
                });
              }
            }
          }
          
          if (notification.consumer_conversation_uid in window.G_bi2_data.IV_conversations_unread_messages_count.value === true) {
            let message_count = window.G_bi2_data.IV_conversations_unread_messages_count.value[notification.consumer_conversation_uid]
            if (message_count > 0) {
              notificationListItem.message_description = `${message_count} new message(s) received from ${conv.consumer_name || "customer"}`;
            }
          }
        }

        notifications.push(notificationListItem);
      }

      CI.DV_unreadNotificationsCount = new_notifications_count;

      if (new_notifications_count !== 0) {
        window.document.title = `(${new_notifications_count}) - Synergy Auto Solutions - Sales Dashboard`;
      } else {
        window.document.title = "Synergy Auto Solutions - Sales Dashboard";
      }

      CI.DV_notificationsList = _.orderBy(notifications, ['timestamp'], ['desc']);
    }, 2000),

    openOrSelectNotification(notification) {
      const CI = this;

      if (CI.DV_selectNotifications === true) {
        CI.toggleNotificationSelection(notification);
        return;
      }

      // Open relevant conversation
      CI.passOpenConversationEvent(notification.consumer_conversation_uid, notification)
      CI.closeNotifocations;
    },

    passOpenConversationEvent(uid, notification=null) {
      const CI = this;
/*
      CI.closeNewConversation();
      CI.$emit('event-open-conversation', uid)
      CI.DV_newConversationSuccessMsg = "";

      CI.markConversationNotificationsAsRead(uid, notification);
*/
    },

    closeNotifocations() {
      const CI = this;

      CI.DV_isNotificationsOpened = false;
      CI.DV_selectNotifications = false;
      CI.DV_selectedNotificationIds = [];
    },

    toggleNotificationSelection(notification) {
      const CI = this;

      if (CI.DV_selectedNotificationIds.includes(notification.uid)) {
        let index = CI.DV_selectedNotificationIds.indexOf(notification.uid);
        CI.DV_selectedNotificationIds.splice(index, 1);
        return;
      }

      CI.DV_selectedNotificationIds.push(notification.uid);
    },

    selectAllNotifications() {
      const CI = this;
      
      let selected_notifications = [];
      for (let notification of CI.DV_notificationsList) {
        selected_notifications.push(notification.uid);
      }

      CI.DV_selectedNotificationIds = selected_notifications;
    },

    unSelectAllNotifications() {
      const CI = this;
      CI.DV_selectedNotificationIds = [];
    },

    deleteAllSelectedNotifications() {
      const CI = this;

      if (CI.DV_selectedNotificationIds.length === 0) {return;}

      let notifier = new AWN();
      notifier.confirm(
        // Label
        "Are you sure, you want to delete "+ CI.DV_selectedNotificationIds.length +" notification(s)?",

        // On OK
        function() {
          for (let notification_id of CI.DV_selectedNotificationIds) {
            let notification = _.find(CI.DV_notificationsList, (notif) => {
              return notif.uid === notification_id;
            });
            if (!notification) {continue;}
            CI.deleteTheNotification(notification)
          }

          CI.DV_selectedNotificationIds = [];
          CI.DV_selectNotifications = false;
        },

        // On Cancel
        function() {},

        // Other options
        {
          labels: {
            confirm: 'Delete Notification(s)',
            confirmOk: "Confirm",
            confirmCancel: "Cancel"
          }
        }
      );
    },

    readAllSelectedNotifications() {
      const CI = this;

      if (CI.DV_selectedNotificationIds.length === 0) {return;}

      let notifier = new AWN();
      notifier.confirm(
        // Label
        "Are you sure, you want to mark "+ CI.DV_selectedNotificationIds.length +" notification(s) as read?",

        // On OK
        function() {
          for (let notification_id of CI.DV_selectedNotificationIds) {
            let notification = _.find(CI.DV_notificationsList, (notif) => {
              return notif.uid === notification_id;
            });
            if (!notification) {continue;}
            CI.markTheNotificationAsRead(notification)
          }

          CI.DV_selectedNotificationIds = [];
          CI.DV_selectNotifications = false;
        },

        // On Cancel
        function() {},

        // Other options
        {
          labels: {
            confirm: 'Mark Notification(s) Read',
            confirmOk: "Confirm",
            confirmCancel: "Cancel"
          }
        }
      );
    },

    confirmAndMarkTheNotificationAsRead(notification) {
      const CI = this;

      let notifier = new AWN();

      notifier.confirm(
        // Label
        "Are you sure, you want to mark this notification as read?",
        // On OK
        function() {
          CI.markTheNotificationAsRead(notification);
        },

        // On Cancel
        function() {},

        // Other options
        {
          labels: {
            confirm: 'Mark as Read',
            confirmOk: "Confirm",
            confirmCancel: "Cancel"
          }
        }
      );
    },

    markTheNotificationAsRead(notification) {
      const CI = this;

      let [consumer_uid, conversation_uid] = notification.consumer_conversation_uid.split("-");
      let currentBusinessUid = window.G_bi2_data.IV_active_employee_info.business_uid;
      let currentRecyclerLocationUid = window.G_bi2_data.IV_active_employee_info.recycler_location_uid;
      let currentEmployeeUid = window.G_bi2_data.IV_active_employee_info.employee_uid;

      // Write to fs125 using fs125_writer
      fs125Writer(
        "n",
        currentBusinessUid,
        currentRecyclerLocationUid,
        currentEmployeeUid,
        notification.consumer_conversation_uid,
        {
          consumer_contact_uid: consumer_uid,
          conversation_uid: conversation_uid,
          read_message_time: new Date(),
          read_notification_time: notification.timestamp
        }
      )

      if (notification.force_mark_unread === true) {
        fs112Writer(
          "e", 
          currentBusinessUid, 
          currentRecyclerLocationUid, 
          notification.uid, 
          {force_mark_unread: false}
        );
      }
    },

    confirmAndMarkTheNotificationAsUnRead(notification) {
      const CI = this;

      //let notifier = new AWN();

      //notifier.confirm(
        // Label
      //  "Are you sure, you want to mark this notification as unread?",
        // On OK
      //  function() {
      let currentBusinessUid = window.G_bi2_data.IV_active_employee_info.business_uid;
      let currentRecyclerLocationUid = window.G_bi2_data.IV_active_employee_info.recycler_location_uid;

      fs112Writer("e", currentBusinessUid, currentRecyclerLocationUid, notification.uid, {force_mark_unread: true});
      //  },

        // On Cancel
        //function() {},

        // Other options
      //  {
      //    labels: {
      //      confirm: 'Mark as Unread',
      //      confirmOk: "Confirm",
      //      confirmCancel: "Cancel"
      //    }
      //  }
      //);
    },

    confirmAndDeleteTheNotification(notification) {
      const CI = this;

      let notifier = new AWN();
      notifier.confirm(
        // Label
        "Are you sure, you want to delete this notification?",
        // On OK
        function() {
          CI.deleteTheNotification(notification);
        },

        // On Cancel
        function() {},

        // Other options
        {
          labels: {
            confirm: 'Delete Notification',
            confirmOk: "Confirm",
            confirmCancel: "Cancel"
          }
        }
      );      
      // 
    },

    deleteTheNotification(notification) {
      const CI = this;

      let currentBusinessUid = window.G_bi2_data.IV_active_employee_info.business_uid;
      let currentRecyclerLocationUid = window.G_bi2_data.IV_active_employee_info.recycler_location_uid;

      fs112Writer("d", currentBusinessUid, currentRecyclerLocationUid, notification.uid);
    },

    getPhoneNumber(conv) {
      let phone_number = ""

      if (conv.phone_number) { 
        phone_number = `${this.formatPhoneNumber(conv.phone_number)}`;
      }

      return phone_number;
    },
  },

  watch: {
    DV_employeesInfo: {
      handler() {
        const CI = this;

        CI.DV_employeePermissions = window.G_bi2_data.IV_active_employee_info.permissions;
        CI.$forceUpdate();
      },

      deep: true
    },

    DV_consumerContactsRaw: {
      handler() {
        this.populateConsumerContactsList();
      },

      deep: true
    },

    DV_paymentLinksRaw: {
      handler() {
        this.populatePaymentLinksList();
      },

      deep: true
    },

    DV_allNotificationsRef: {
      handler() {
        const CI = this;
        CI.lazyGenerateListOfNotifications();
      },
      deep: true
    },
  }
};
</script>

<style scoped>
button {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
</style>

<style>
table tbody td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
